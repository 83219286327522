#contact{
    h4{
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 3.1rem;

        @media (max-width: 767px) {
          font-size: 2.5rem;
        }
        strong{
            font-weight: 900;
        }
    }

    input{
        font-weight: 300;
        border-radius: inherit;
        border: none;
        height: calc(100% + .52rem);

        &[type='submit']{
            height: calc(100% - .45rem);
        }

        &::placeholder{
            color: $gray-500;
        }
    }

    .newsletter-subscribe{
        label{
             margin-top: .5rem;
             font-size: .9rem;
        }
        #gdpr{
            height: auto;
        }
    }

    .contact-info{
        h1{
            font-size: 1rem;
            font-weight: 700;

            &:before{
                content: '';
                display: block;
                width: 2rem;
                height: 5px;
                margin-bottom: 1rem;
                background-color: $secondary;
            }
        }

        h4{
            font-size: 2.8rem;
            font-weight: 700;
        }
    }

    .maps{
        img{
            width: 100%;

            @media (min-width: 576px) {
                margin-left: -5%;
                width: 110%;
            }

            // @include media-breakpoint-down(sm){
            //     width: 100%;
            // }
        }
    }
}
