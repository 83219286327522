.page-group{

    .top-heading{
        position: relative;
        overflow: hidden;
        min-height: 20rem;
        background-image: url('../assets/img/hero-placeholder.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(0deg, rgba($black, .5), transparent);
        }

        .heading{
            .title{
                color: $white;
                font-size: 4.5rem;
                font-weight: 300;
                line-height: 3.8rem;
                text-transform: uppercase;
                z-index: 1;

                @include media-breakpoint-down(sm){
                    font-size: 2.8rem;
                    line-height: 3rem;
                }

                strong{
                    font-weight: 900;
                }
            }

            .icon{
                color: $white;
                font-size: 2rem;
                width: 3.5rem;
                height: 3.5rem;
                line-height: 0;
                vertical-align: middle;

                &.play-btn{
                    position: relative;
                    display: inline-block;
                    padding: 1rem;
                    border: 2px solid $white;
                    border-radius: 50%;
                    transition: all .4s ease;

                    i{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-40%, -50%);
                        transition: all .6s ease;
                    }

                    &:hover{
                        background-color: $white;

                        i{
                            color: $gray-500;
                        }
                    }
                }
            }
        }

        #background-video{
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
            z-index: -1;

            @include media-breakpoint-down(sm){
                height: 100%;
                width: inherit;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    #history{
        position: relative;

        .heading{
            font-size: 3.5rem;
            font-weight: 300;
            line-height: 3.1rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){
                font-size: 2.8rem;
                line-height: 3rem;
            }

            &:before{
                content: '';
                display: block;
                position: relative;
                height: 1.4rem;
                width: 1.4rem;
                margin-bottom: 2rem;
                background-color: $primary;
            }
        }

        img{
            width: 80%;
        }

        .anniversary-logo{
            width: auto;
        }

        &:after{
            content: '';
            position: absolute;
            display: block;
            width: 80%;
            height: 100%;
            background-image: url('../assets/img/vectors/world-map.svg');
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: bottom;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: -1;

            @include media-breakpoint-down(sm){
                width: 125%;
            }
        }
    }

    #gallery{
        cursor: pointer;

        .history-carousel{
            &:before{
                content: '';
                position: absolute;
                height: 100%;
                width: calc(100vw - 100%);
                left: calc(-100vw + 100%);
                top: 0;
                background-color: $white;
            }

            .owl-item{
                opacity: .3;
                transition: opacity .4s ease;

                &.active{
                    opacity: 1;
                }

                .item{
                    position: relative;
                    width: 350px;
                    max-height: 340px;

                    img{
                        height: 340px;
                    }

                    .caption{
                        position: absolute;
                        left: 0;
                        top: 0;
                        max-height: 2rem;
                        width: 100%;
                        padding: .5rem;
                        color: $white;
                        background-image: linear-gradient( rgba($black, .5), transparent);
                    }
                }
            }
        }
    }

    #services{

        .heading{
            .title{
                font-size: 2.5rem;
                font-weight: 300;
                line-height: 2.1rem;
                text-transform: uppercase;
                z-index: 1;

                @include media-breakpoint-down(sm){
                    font-size: 2.8rem;
                    line-height: 3rem;
                }

                strong{
                    font-weight: 900;
                }
            }
            &:before{
                content: '';
                display: block;
                position: relative;
                height: 1.4rem;
                width: 1.4rem;
                margin-bottom: 2rem;
                background-color: $secondary;
            }
        }

        .content{
            ul{
                border: 1px solid $gray-400;

                li{
                    padding: .5rem;

                    &:not(:first-child){
                        border-top: 1px solid $gray-400;
                    }
                }
            }
        }

        .services-list{

            .service{
                font-size: .8em;
                background-color: $white;
                border-bottom: 5px solid $primary;
                box-shadow: 0 .25rem .5rem rgba($black, .1);

                .title{
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
        }
    }

    #padoan-group{
        background-image: url('../assets/img/vectors/world-map.svg');
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: -100%;
        background-color: $gray-100;

        .our-group{
            .expansion{

                .title{
                    font-weight: 300;
                    font-size: 3.5rem;
                    line-height: 3.1rem;
                    text-transform: uppercase;

                    @include media-breakpoint-down(sm){
                        font-size: 2.8rem;
                        line-height: 3rem;
                    }
                }

                .item{
                    position: relative;
                    cursor: pointer;

                    .icon{
                        position: absolute;
                        display: inline-block;
                        padding: .5rem 1rem;
                        top: 50%;
                        left: 50%;
                        color: $white;
                        font-size: 1.5rem;
                        background: rgba(0,0,0, .3);
                        transform: translate(-50%,-50%);
                        opacity: 0;
                        pointer-events: none;

                        transition: opacity .7s ease;
                    }

                    &:hover{
                        .icon{
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .company{
            border-bottom: 1px solid $gray-300;

            &.main{
                border-bottom: none;
            }
            .nation{
                font-size: 1.8rem;
                font-weight: 300;
            }

            .contacts{
                font-size: .8rem;

                .address{
                    font-size: .9rem;
                }
                .mail{
                    color: $gray-500;
                }
            }

            .headquarter{
                width: 100%;
                border-left: .5rem solid $primary;
                background-color: $white;
                box-shadow: 0 .25rem .5rem rgba($black, .1);
            }
        }
    }

    #shortcut{
        .heading{
            .title{
                font-size: 2rem;
                font-weight: 300;
                line-height: 1.8rem;
            }

            .icon{
                font-size: 2rem;
            }
        }

        .certification,
        .research-development{
            padding: 4rem 1rem 3rem;
            z-index: 0;
            cursor: pointer;
        }

        .certification{
            color: $white;
            background-color: $primary;
            transition: color .4s ease, background-color .4s ease, transform .4s ease, box-shadow .4s ease;
            &:hover{
                background-color: lighten($primary, 5%);
                transform: scale(1.1);
                box-shadow: 0 .25rem 1rem rgba($primary, .5);
                z-index: 1;
            }
        }
        .research-development{
            background-color: $gray-200;
            transition: color .7s ease, background-color .4s ease, transform .4s ease, box-shadow .4s ease;
            &:hover{
                color: $white;
                background-color: $secondary;
                transform: scale(1.1);
                box-shadow: 0 .25rem 1rem rgba($secondary, .5);
                z-index: 1;
            }
        }

    }

}
