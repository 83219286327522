.page-product{

    .text-capsfirstletter{
        text-transform: lowercase;

        *,
        strong{
            &:first-letter{
                text-transform: uppercase;
            }
        }

         &:first-letter{
             text-transform: uppercase;
         }
    }

    #product{
        .product-info{

        }

        .product-specs{

            .product-description{
                .heading{
                    h1{
                        position: relative;
                        font-weight: 900;
                        background-color: $white;
                        z-index: 2;
                    }
                    h2{
                        font-size: 1.125rem;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    h3{
                        font-size: 1.5rem;

                        &.animated-input{
                            z-index: 1;
                        }
                    }
                }
            }

            .technical-data{

                .capacity,
                .dimension,
                .evo,
                .material,
                .npt-drainage,
                .product-id{
                    position: relative;
                    padding: 1rem 0;
                    background-color: $white;
                    border-bottom: 1px solid $black;
                    z-index: 2;

                    &.animated-input{
                        z-index: 1;
                    }

                    label{
                        margin: 0;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 700;
                    }

                    fieldset{
                        .radio-selection{

                            float: left;
                            &:not(:first-child){
                                margin-left: 1rem;
                            }

                            input{
                                display: none;

                                ~ label{
                                    position: relative;
                                    transition: color .4s ease;
                                    cursor: pointer;

                                    &:after{
                                        content:'';
                                        display: block;
                                        position: absolute;
                                        width:0;
                                        height: 2px;
                                        bottom: 0;
                                        left: 50%;
                                        background-color: $primary;
                                        transform: translateX(-50%);
                                        transition: width .4s ease, transform .4s ease;
                                    }

                                    &:hover{
                                        color: $primary;

                                        &:after{
                                            width:100%;
                                        }
                                    }
                                }
                                &:checked{
                                    ~ label{
                                        color: $primary;
                                        &:after{
                                            width:100%;
                                        }
                                    }
                                }
                            }
                        }
                        label{
                            margin: 0 .5rem;
                            font-family: 'Helvetica Neue', sans-serif;
                            font-weight: 300;
                            text-transform: inherit;

                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }

                .evo{
                    input{
                        &[type="checkbox"]{
                            display:none;

                            ~ label{
                                width: 100%;
                                max-width: 135px;
                                margin-bottom: 0;
                                padding: 0 0 0 1rem;
                                position: relative;
                                font-weight: 700;
                                text-transform: capitalize;
                                background-color: transparent;
                                overflow: hidden;
                                transition: color .4s ease, padding-left .6s ease;
                                text-decoration: line-through;

                                i{
                                    margin-left: 1rem;
                                    padding: .5rem;
                                    color: white;
                                    font-size: 1.4rem;
                                    vertical-align: middle;
                                    transition: color .4s ease, background-color .4s ease;
                                }

                                &:after{
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 100%;
                                    width: 0;
                                    background-color: #DE6511;
                                    z-index: -1;

                                    transition: width .4s ease;
                                }

                                &:hover{
                                    color: #DE6511;
                                    padding-left: 1rem;
                                    text-decoration: none;
                                    transition: padding-left .2s ease;

                                    i{
                                        color: darkorange;
                                    }

                                    &:after{
                                        width: 6px;
                                    }
                                }
                            }

                            &:checked{
                                ~ label{
                                    color: white;
                                    text-decoration: none;

                                    padding: 0 0 0 1rem;
                                    transition: background-color .4s ease;

                                    i{
                                        background-color: lightsalmon;
                                    }

                                    &:after{
                                        width: 100%;
                                    }

                                    &:hover{
                                        background-color: #EF7F31;
                                        i{
                                            color: white;
                                            background-color: darkorange;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .extra{
                    padding: 1rem 0;
                }

            }
        }
    }

    .animated-input{
        display: block!important;
        margin-top: 0;
        height: auto;
        opacity:1;
        transition: margin .7s ease, opacity .7s ease, height .7s ease;

        &.ng-hide{
            display: block!important;
            margin-top: -3rem;
            opacity:0;
            height: 3rem;
            z-index:0 !important;
            pointer-events: none
        }
    }

    .hint{
        position: relative;
        min-width: 190px;
        @media (max-width: 767px){
            min-width: 165px;
        }

        i{
            font-size: 1.5rem;
            color: #dee2e6;
            vertical-align: middle;
            transition: color .4s ease;
        }
        .hint-content{
            display: inline-block;
            position: absolute;
            padding: .6rem 1rem;
            top: 50%;
            left: 8%;
            max-width: 90%;
            color: white;
            font-size: .8rem;
            background-color: rgba(black,.7);
            border-left: 5px solid #EF7F31;
            border-radius: 3px;
            z-index:10;
            opacity: 0;
            transform: translate(-10%, -50%);
            transition: opacity .4s ease, transform .4s ease;
            pointer-events: none;

            &:first-letter{
                text-transform: uppercase;
            }
            .level-indicator-img{
                min-width: 230px;
            }
            .dimensions-indicator-img{
                display: block;
                max-width: 140px;
                margin: 1rem auto;
            }
        }

        &:hover{
            i{
                color: darkorange;
            }
            .hint-content{
                opacity: 1;
                transform: translate(0, -50%);
            }
        }

        &.in-view{
            width: 100%;
            i{
                color: darkorange;
                margin-right: .5rem;
            }
            .hint-content{
                display: inline-block;
                position: relative;
                padding: .4rem 1rem;
                opacity: 1;
                left: 0;
                color: darkorange;
                font-size: .7rem;
                font-weight: 500;
                text-transform: uppercase;
                background-color: rgba(darkorange,.3);
                transform: translate(0, 0);
            }
        }
    }

    &.accessory{
        .technical-data{
            .extra{
                &:not(:last-of-type){
                    border-bottom: 1px solid $black;
                }
            }
        }
    }
}
