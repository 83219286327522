.page-news-events{
    #intro{
        .title{
            font-size: 3.5rem;
            font-weight: 300;
            line-height: 3.1rem;
            text-transform: uppercase;

            @media (max-width: 767px) {
              font-size: 2.5rem;
            }

            strong{
                font-weight: 900;
            }
        }
    }

    #list{

        .section-title{
            font-size: 1.6rem;
            font-weight: 600;
            background-color: $white;
            border-bottom: 1px solid $gray-400;

            i{
                color: $gray-400;
                transition: transform .4s cubic-bezier(0.30, -0.30, 0.50, 1.40);
                &.active{
                    transform: rotate(-135deg);
                }
            }
        }
    }
}
