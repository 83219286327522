header{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 3;

    transition: transform .7s ease;

    .top-header{
        color: $white;
        background-color: darken($secondary, 10%);

        .contacts{
            small{
                margin: 0 .5rem;

                img{
                    margin-right: .5rem;
                    vertical-align: middle;
                }
            }
        }
        .social-icons{
            margin-left: 2rem;
            small{
                margin-left: .5rem;
            }
        }
    }

    .main-header{
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background-color: $white;
        box-shadow: 0 3px 6px rgba($black, 0);

        transition: box-shadow 1s ease;

        .logo{
            min-width: 220px;
            max-width: 240px;
        }

        .navbar{
            @include media-breakpoint-down(md){
                width: 100%;
            }
        }

        .navbar-nav{
            font-family: 'Roboto', sans-serif;

            .nav-item{
                margin: 0 .5rem;
                text-transform: uppercase;

                @include media-breakpoint-between(md,xl){
                    margin: 0 .3rem;
                    font-size: .85rem;
                }

                &.dropdown{
                    .dropdown-menu{
                        min-width: inherit;
                        border: none;
                        border-bottom: 5px solid $primary;
                        border-radius: 0;

                        .dropdown-item{
                            font-size: .95rem;
                            font-weight: 300;

                            &:active{
                                color: $black;
                                background-color: $gray-100;
                            }
                        }
                    }
                }
            }
        }

        .right-menu{
            .navbar-nav{
                font-family: 'Roboto', sans-serif;

                .nav-item{
                    margin: 0 .5rem;
                    font-size: .95rem;
                    text-transform: inherit;

                    &:first-of-type{
                        a{
                            color: $secondary;

                            &::after{
                                content: '→';
                                display: inline-block;
                                margin-left: .5rem;
                            }
                        }
                    }

                    .nav-link{
                        &.dropdown-toggle{
                            outline: none;
                        }
                    }


                    .newsevents{
                        color: $secondary;
                        letter-spacing: -.01rem;
                    }
                }
            }
        }
    }

    &.translate{
        transform: translateY(-2.5rem);

        @include media-breakpoint-down(sm){
            transform: translateY(0);
        }

        .main-header{
            box-shadow: 0 3px 6px rgba($black, .1);
        }
    }
}
