.showroom{
    .countdown {
	
		.day {
			display: inline-block;
			color: #004984;
			
			span {
				font-size: 8rem;
				
				@media (max-width: 767px) {
				  font-size: 3.5rem;
				}
			}
			
			.days-text {
				font-size: 2em;
				
				@media (max-width: 767px) {
				  font-size: 1rem;
				}
			}
		}
		
		.hour {
			display: inline-block;
			color: #004984;
			
			div {
				min-width: 95px;
				color:  #000;
				
				@media (max-width: 767px) {
					min-width: 65px;
				}
			}
			
			span {
				font-size: 4rem;
				line-height: 4rem;
				color: #004984;
				
				@media (max-width: 767px) {
					font-size: 2rem;
					line-height: 2rem;
				}
			}
		}
	}
	
}
