.products-carousel{
    height: calc(100% - 10rem);

    .owl-stage-outer{
      overflow: inherit;

      .owl-stage{
        height: 100%;
        overflow: inherit;

        .owl-item{
          height: 100%;
          width: 100%;

          .item{
            height: 100%;

            img{
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;

            }
          }
        }
      }
    }
}
