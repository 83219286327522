.page-default{
    .title{
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 3.1rem;
        text-transform: uppercase;

        @media (max-width: 767px) {
          font-size: 2.5rem;
        }

        strong{
            font-weight: 900;
        }
    }
}
