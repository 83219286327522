.lg-backdrop{
  background-color: rgba($black, .85);
  backdrop-filter: blur(.8rem);
}

.lg-outer{

  .lg-inner{
    height: 85%;
    top: 50%;
    transform: translateY(-50%);
  }

  .lg-thumb{
    margin: 0 auto;

    .lg-thumb-item{
      border:none;
      border-radius: 0;

      transition: transform .4s ease, opacity .7s ease;

      &:not(.active){
        opacity: .1;
        transform: scale(.92);
      }

      &.active,
      &:hover{
        border-color: inherit;
      }
    }
  }
  .lg-toolbar{
    background-color: transparent;

    .lg-icon{
      color: $white;
      font-size: 1rem;
    }

    .lg-autoplay-button{
      &:after{
        // content:'\e941';
        display: inline-block;
        // transform: rotate(-90deg);
      }
    }

    .lg-close{
      // background-color: $primary;

      &:after{
        // content:'\e90e';
      }

      &.lg-icon{
        // color: $black;
      }
    }

    #lg-share{

      &:after{
        // content: '\e915';
        font-size: 1.2rem;
      }

      .lg-dropdown{
        padding: 0;
        background-color: rgba($black, .2);

        li{
          a{
            color: $white;

            &#lg-share-facebook{

              .lg-icon{
                padding: .5rem .5rem .1rem;
                color: $black;
                vertical-align: -.4rem;
                border-radius: 1rem;
                background-color: $primary;

                &:after{
                  // content: '\e900';
                  vertical-align: .4rem;
                }
              }
            }
          }
        }

        &:after{
          display: none;
        }
      }
    }


  }


  .lg-actions{
    .lg-icon{
      font-size: 1rem;
      color: $white !important;
    }

    .lg-next{
      padding: 1rem .8rem 1rem 1rem;
      right: 0;
      max-width: 40px;
      font-size: 1rem;
      text-align: center;
      border-radius: 0;
      background-color: $primary;
      transform: translateX(0);

      transition: transform .4s ease;

      &:hover{
        color: inherit;
        background-color: lighten($primary, 15%);
      }

      &:before{
        // content: '\e943';
      }

      &.disabled{
        opacity: 1;
        transform: translateX(110%);
      }

      @include media-breakpoint-down(sm){
        display: none;
      }
    }

    .lg-prev{
      @extend .lg-next;
      padding: 1rem 1rem 1rem .8rem;
      left: 0;

        &:before{
          content: '';
          display: none;
        }
        &:after{
          // content: '\e903'
        }

        &.disabled{
          opacity: 1;
          transform: translateX(-110%);
        }
    }
  }

  .lg-progress-bar{
    top: inherit;
    bottom: 0;
    display: block;

    .lg-progress{
      background-color: $primary;
    }
  }

  .lg-sub-html{
    background-color: rgba($black, 0);

    &.expanded{
      background-color: rgba($black, .5);
    }
  }

  .lg-thumb-outer{
    .lg-icon{
      color: $white;
      font-size: 1rem;
    }

    .lg-toogle-thumb{
      top: -40px;
      right: 0;
      background-color: transparent;
      &:after{
        // content: '\e908';
      }
    }
  }
}




// Temporary

// .lg-download{
//   display: none;
// }
