footer{
    font-family: 'Roboto', sans-serif;
    color: $white;
    background-color: $black;

    .logo{
        img{
            max-width: 100px;
        }
    }

    .menu{
        .item{
            h3{
                font-size: 1rem;
                text-transform: uppercase;
            }

            ul{
                padding-left: 0;
                margin-left: 0;
                list-style: none;
                font-size: .875rem;

                &.main-pages{
                    font-size: 1rem;
                    font-weight: 500;
                }

                li{
                    a{
                        color: $white;
                    }
                }
            }
        }

        &.main-pages{
            text-transform: uppercase;
        }
    }

    .reserved-area-login{
        h4{
            font-size: 1.75rem;
            font-weight: 300;
            text-transform: uppercase;
        }
        p{
            font-size: 1rem;
        }
    }

    .sub-footer{
        font-family: 'Helvetica Neue', sans-serif;
        color: $gray-400;
        font-size: .75rem;
        background-color: $gray-900;

        a{
            color: $gray-400;
        }

        .copyright{
            color: $gray-300;
            font-size: .625rem;

            a{
                color: $gray-300;
            }
        }
    }
}
