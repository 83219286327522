#breadcrumbs{
    color: $gray-500;
    text-transform: uppercase;
    background-color: $gray-200;

    .back-link,
    .links{
        a{
            color: $gray-500;
            transition: color .4s ease;

            &:hover{
                color: $gray-700;
            }
        }
    }

    .back-link{
        .left-arrow{
            &:before{
                content: '←';
                display: inline-block;
                // vertical-align: middle;
            }
        }
    }
}
