.page-article{
    .post-header{
        max-height: 330px;
        width:100%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;

        img{
            width: 100%;
        }
    }

    .article-content{
        .title{
            line-height: 2.3rem;
            text-transform: uppercase;

            strong{
                font-weight: 900;
            }
        }

        .post-info{
            font-size: .75rem;
            text-transform: uppercase;
            .date{
                color: $gray-500;
            }
            .category{
                padding: .3rem 1rem;
                color: $white;
                background-color: $secondary;
            }
        }

        .information{


            .label{
                font-size: 1rem;
                font-weight: 700;
                text-transform: uppercase;
                border-left: 5px solid $secondary;
            }

            .savethedate{
                background-color: $white;
                box-shadow: 0 0 1rem 0 rgba($black, .1);

                .event-info{
                    .name{
                        font-weight: 900;
                        text-transform: uppercase;
                    }
                    .date{

                    }
                    .stand{
                        font-weight: 700;
                    }
                    .map{
                        width: 100%;
                    }
                }
            }

            .data-link{
                position: absolute;
                bottom: 0;
                width: 100%;
                transform: translateY(100%);

                transition: transform .4s ease;

                a{
                    display: block;
                    width: 100%;
                    color: $white;
                    font-size: 1.6rem;
                    font-weight: 700;
                    border-radius: inherit;
                    background-color: $primary;

                    &:hover{
                        background-color: lighten($primary, 5%);
                    }

                    &:active{
                        background-color: darken($primary, 5%);
                    }
                }
            }
        }
    }
}
