.select-input{
    select {
        position: relative;
        font-size: 1rem;
        font-weight: 700;padding-right: 1rem;
        direction: rtl;
        border:none;
        -webkit-appearance:none;

        &:focus{
            outline: none;
        }

        option{
            direction: ltr;
        }
    }

    &:after{
        content: '';
        display: inline-block;
        position: absolute;
        color: $gray-500;
        font-family: 'Ionicons';
        font-size: 1.3rem;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);

        pointer-events: none;
    }
}
