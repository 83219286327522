.page-home{

    #hero{
        position: relative;
        height: 85vh;
        color: $white;
        border: 2rem solid $white;
        border-top: none;
        //background-image: url('../assets/img/hero-placeholder.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black,.2);
        }

        @include media-breakpoint-down(sm){
            border: 1rem solid $white;
            border-top: none;
			background-image: url('../assets/img/hero-placeholder.jpg');
        }
		@media screen and (max-width: 768px) {
            border: 1rem solid $white;
            border-top: none;
			background-image: url('../assets/img/hero-placeholder.jpg');
        }

        #background-video{
            position: absolute;
            top: 50%;
            left: 0;
            min-width: 100%;
            width: 100%;
            // height: 115%;
            transform: translateY(-55%);
            z-index: -1;

            @include media-breakpoint-down(sm){
                height: 100%;
                width: inherit;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .latest-news{
            position: relative;
            overflow-x: hidden;

            .tile{
                &:not(:first-of-type){
                    display: none;
                }
            }

            .heading{
                font-weight: 500;
            }

            &:before{
                content: '';
                position: absolute;
                height: 100%;
                width: 10%;
                left: 0;
                top: 0;
                background-image: linear-gradient(90deg, rgba($black, .2), transparent);
            }

            .news-link{
                color: $white;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .reservedarea-login{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 4rem;

            .login-link{
                position: relative;
                min-height:1.5rem;
                color: $white;
                background-color: $primary;

                a{
                    color: $white;
                    font-weight: 400;
                }

                &:before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: calc(100vw - 100%);
                    left: calc(-100vw + 100%);
                    top: 100%;
                    background-color: $secondary;
                }

                &:after{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: calc(100vw - 100%);
                    left: 100%;
                    top: 0;
                    background-color: $primary;
                }
            }
        }
    }

    #padoan-group{
        .container{
            border-bottom: 1px solid $gray-300;
        }

        .heading{
            font-weight: 300;
            line-height: 1;
            text-transform: uppercase;
        }

        .mission-vision{
            .vision2030{
                color: $gray-500;
                font-size: .8rem;
                background-color: $white;
                box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
                transition: color .4s ease;

                .logo{
                    max-height: 30px;
                }

                a{
                    color: $gray-500;
                    font-family: 'Roboto', 'Helvetica', sans-serif;
                    font-weight: 700;
                    transition: color .4s ease;
                }

                p{
                    margin-top: .9rem;
                }

                &:hover{
                    color: $black;

                    a{
                        color: $secondary;
                    }
                }
            }
        }

        .our-group{
            .heading{
                font-size: 3.5rem;
                font-weight: 300;
                line-height: 3.1rem;

                @include media-breakpoint-down(sm){
                    font-size: 2.8rem;
                }
            }


            .company{
                font-size: .75rem;
                opacity: .3;
                filter: saturate(0);

                transition: opacity .4s ease, filter .4s ease;

                a{
                    color: $black;
                }

                .logo{
                    max-width: 240px;
                }

                &:hover,
                &.active{
                    opacity: 1;
                    filter: saturate(1);
                }
            }
        }
    }

    #history{
        position: relative;

        .heading{
            font-size: 3.5rem;
            font-weight: 300;
            line-height: 3.1rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){
                font-size: 2.6rem;
            }
			@media screen and (max-width: 768px){
                font-size: 2.6rem;
            }
        }

        img{
            width: 80%;
        }

        .anniversary-logo{
            width: auto;
        }

        &:after{
            content: '';
            position: absolute;
            display: block;
            width: 80%;
            height: 100%;
            background-image: url('../assets/img/vectors/world-map.svg');
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: bottom;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: -1;

            @include media-breakpoint-down(sm){
                width: 125%;
            }
        }
    }

    #products{
        .product-text{
            position: relative;
            background-color: $white;
            z-index: 2;

            &:before{
                content: '';
                position: absolute;
                height: 100%;
                width: calc(100vw - 100%);
                left: calc(-100vw + 100%);
                top: 0;
                background-color: $white;
            }

            .heading{
                font-size: 3.5rem;
                font-weight: 300;
                line-height: 3.1rem;
                text-transform: uppercase;

                @include media-breakpoint-down(sm){
                    font-size: 2.8rem;
                }
            }

            .link{
                color: $secondary;
                font-family: 'Roboto', 'Helvetica', sans-serif;
                font-weight: 700;
            }

        }

        .products-carousel{
            &:before{
                content: '';
                position: absolute;
                height: 100%;
                width: calc(100vw - 100%);
                left: calc(-100vw + 100%);
                top: 0;
                background-color: $white;
            }

            .owl-item{
                opacity: .3;
                transition: opacity .4s ease;

                &.active{
                    opacity: 1;
                }

                .item{
                    width: 350px;

                    &.brand-new{
                        &:before{
                            content: 'NEW';
                            position: absolute;
                            height: auto;
                            width: auto;
                            left: 0;
                            top: 2rem;
                            padding:.2rem .5rem;
                            color: $white;
                            font-size: .8rem;
                            font-weight: 600;
                            background-color: $primary;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    #news{
        .heading{
            max-width: 80%;
            font-size: 2rem;
            font-weight: 300;
            line-height: 1;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){
                font-size: 1.8rem;
            }

            &:before{
                content: '';
                display: block;
                position: relative;
                height: 1.4rem;
                width: 1.4rem;
                margin-bottom: 2rem;
                background-color: $secondary;
            }
        }

        .latest-events{
            .tile{
                padding:0;

                article{
                    margin-left: 0 !important;
                }
                &.events,
                &.eventi{
                    display: block;
                }

                &.news{
                    display: none;
                }
            }
        }

        .latest-news{
            .tile{
                padding:0;

                article{
                    margin-left: 0 !important;
                }

                &.news{
                    display: block;
                }

                &.events,
                &.eventi{
                    display: none;
                }
            }
        }

        .news-link{
            color: $secondary;
            font-family: 'Roboto', 'Helvetica', sans-serif;
            font-weight: 700;
        }
    }
}
