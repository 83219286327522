.owl-carousel{

    .owl-stage-outer{
      overflow: hidden;

      .owl-stage{
        height: 100%;
        overflow: hidden;

        .owl-item{
          height: 100%;
          width: 100%;

          .item{
            height: 100%;

            img{
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;

            }
          }
        }
      }
    }

    .owl-dots {
      text-align: center;
      position: absolute;
      bottom: 1rem;
      width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;

      .owl-dot {
        display: inline-block;
        margin-left: .2rem;
        margin-right: .2rem;
        height: .42rem;
        width: .42rem;
        border-radius: 50px;

        span{
          display: block;
          height: 100%;
          width: 100%;
          border-radius: .42rem;
          background-color: rgba($white, .6);

          transition: transform .5s ease, background-color .7s ease;
        }

        &.active{
          span{
            background-color:rgba($white, 1);
            transform: scale(1);
          }
        }

        &:first-child,
        &:last-child{
            transform: scale(.7);
            transition: transform .5s ease;

            &.active{
                transform: scale(1);
            }
        }
      }
    }
}


.slide-news-home {
	.owl-dots {
      text-align: center;
      position: absolute;
      bottom: -1.5rem!important;
      width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;

      .owl-dot {
        display: inline-block;
        margin-left: .2rem;
        margin-right: .2rem;
        height: .42rem;
        width: .42rem;
        border-radius: 50px;

        span{
          display: block;
          height: 100%;
          width: 100%;
          border-radius: .42rem;
          background-color: rgba(173, 173, 173, .6)!important;

          transition: transform .5s ease, background-color .7s ease;
        }

        &.active{
          span{
            background-color:#EE7623!important;
            transform: scale(1);
          }
        }

        &:first-child,
        &:last-child{
            transform: scale(.7);
            transition: transform .5s ease;

            &.active{
                transform: scale(1);
            }
        }
      }
    }
}

@import "carousel/_hero.scss";

@import "carousel/_products.scss";
