.padoan-carousel{
    height: calc(100% - 8rem);

    @include media-breakpoint-down(sm){
        height: calc(100% - 4rem);
    }

    .item{
        width: 100%;

        .title{
            font-size: 3.5rem;
            font-weight: 400;
            line-height: 1;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){
                font-size: 2.8rem;
            }
        }

        .text{
            font-size: 1.4rem;
        }
    }

    .dots-indicator{
        background-color: rgba(yellow, .3);
    }
}
