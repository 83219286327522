#page-punti-di-forza{
  .heading{
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 3.1rem;
    text-transform: uppercase;
    margin-bottom:3rem;
  }
  .heading-products{
      font-size: 2.5rem;
      font-weight: 300;
      line-height: 3.1rem;
      text-transform: uppercase;
      margin-bottom:2rem;

      &:before{
          content: '';
          display: block;
          position: relative;
          height: 1.4rem;
          width: 1.4rem;
          margin-bottom: 2rem;
          background-color: $primary;
      }
  }
  .heading-processes{
      font-size: 2.5rem;
      font-weight: 300;
      line-height: 3.1rem;
      text-transform: uppercase;
      margin-bottom:2rem;

      &:before{
          content: '';
          display: block;
          position: relative;
          height: 1.4rem;
          width: 1.4rem;
          margin-bottom: 2rem;
          background-color: $secondary;
      }
  }
}
