@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

/*
//* HELVETICA NEUE WEBFont
*/

@font-face {
	font-family: 'Helvetica Neue';
	src: url('fonts/HelveticaNeueLTPro-Lt.eot');
	src: url('fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
		url('fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
		url('fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
		url('fonts/HelveticaNeueLTPro-Lt.otf') format('opentype'),
    url('fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
		url('fonts/HelveticaNeueLTPro-Lt.svg#') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('fonts/HelveticaNeueLTPro-Blk.eot');
	src: url('fonts/HelveticaNeueLTPro-Blk.eot?#iefix') format('embedded-opentype'),
		url('fonts/HelveticaNeueLTPro-Blk.woff2') format('woff2'),
		url('fonts/HelveticaNeueLTPro-Blk.woff') format('woff'),
		url('fonts/HelveticaNeueLTPro-Blk.otf') format('opentype'),
    url('fonts/HelveticaNeueLTPro-Blk.ttf') format('truetype'),
		url('fonts/HelveticaNeueLTPro-Blk.svg#') format('svg');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
}
