.btn-default{
    padding: .8rem 3rem;
    color: $white;
    font-size: .8rem;
    text-align: center;
    text-transform: uppercase;

    &.btn-primary{
        background-color: $primary;
        &:hover,
        &:active{
            background-color: lighten($primary, .5);
        }
    }

    &.btn-secondary{
        background-color: $secondary;
        &:hover,
        &:active{
            background-color: lighten($secondary, .5);
        }
    }
}
