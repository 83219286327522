#contact-form{
    color: $white;
    font-family: 'Roboto',sans-serif;
    background-color: $secondary;


        .company{
            border-bottom: 1px solid $white;
            .nation{
                font-size: 1.8rem;
                font-weight: 300;
            }

            .contacts{
                font-size: .8rem;

                .address{
                    font-size: .9rem;
                }
                .mail{
                    color: $white;
                }
            }
        }

    .heading{
        h2{
            font-weight: 300;
        }

        .emphasis{
            display: block;
            color: $primary;
            font-weight: 300;
        }
    }
    form{
        .form-group{
            label{
                display: none;
                position: absolute;
                top: 0;
                margin: 0;
                padding: 0.375rem 0.75rem;
                height: calc(1.5em + 0.75rem + 2px);
                color: red;
                line-height: 2;

                &.gdpr{
                    display: inline-block;
                    position: relative;
                    margin: 0;
                    padding: inherit;
                    height: auto;
                    color: $white;
                    line-height: inherit;

                    a{
                        color: $primary;
                    }
                }
            }

            input,
            textarea{
                font-weight: 300;
                border-radius: inherit;
                border: none;

                &::placeholder{
                    color: $gray-500;
                }
            }

            textarea{
                min-height: 12rem;
            }

            small{
                color: $white;
                opacity: .3;
            }


        }

    }
}
