body{
  font-family: 'Helvetica Neue', Roboto, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont,  "Segoe UI", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
    outline: none;
}

h1
{
  font-weight: 700;

  strong,
  b{
    font-weight: 900;
  }
}

h2,
h3{
  font-weight: 500;

  strong,
  b{
    font-weight: 900;
  }
}

h4{
    font-weight: 400;

    strong,
    b{
      font-weight: 900;
    }
}

h5{
  font-weight: 400;
}

h6{
  font-weight: 300;
}

strong,
b{
  font-weight: 700;
}

em{
  font-style: italic;
}

a{
  color: $black;
  text-decoration: none;

  transition: color .5s ease;

  &:hover{
    color: $secondary;
    text-decoration: none;
  }
}

button{
  display: inline-block;
  padding-top: 1rem;
  padding-right: 1.2rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  color: $black;
  font-size: .75rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $primary;
  border: none;

  transition: background-color .7s ease, color .3s ease;

  &:hover, &:focus{
    color: $black;
    background-color: lighten($primary, 20%);
  }

  &:active, &:active:hover{
    color: darken($primary, 25%);
    background-color: darken($primary, 5%);
  }
}

form{

  // input[type="search"]{
  //   color: $gray-900;
  //   border: none;
  //   border-radius: inherit;
  //   line-height:2.2rem;
  //   background-color: $primary;
  //
  //   @include media-breakpoint-down(sm){
  //     background-color: $white;
  //   }
  //
  //   &::placeholder{
  //     color: $gray-900;
  //     font-size: .9rem;
  //     font-weight: 500;
  //     letter-spacing: .05rem;
  //     text-transform: uppercase;
  //     background-color: $primary;
  //
  //     @include media-breakpoint-down(sm){
  //       color: $gray-600;
  //       font-weight: 300;
  //       letter-spacing: inherit;
  //       text-transform: inherit;
  //       background-color: $white;
  //     }
  //   }
  //   &:focus{
  //     border: none;
  //     border-color: inherit;
  //     background-color: $primary;
  //     box-shadow: inherit;
  //
  //     @include media-breakpoint-down(sm){
  //       background-color: $white;
  //     }
  //   }
  // }

  // button[type="submit"]{
  //   @extend button;
  //   background-color: $black;
  //
  //   i{
  //     font-size: 1rem;
  //   }
  //
  //   &:hover{
  //     background-color: lighten($black, 25%);
  //   }
  // }
}

.page-content{
    overflow:hidden;
}

section{
    position: relative;
}

.padoan-lines{
    position: relative;
    display: block;
    width: 2rem;
    height: 1.4rem;
    background-color: $secondary;

    &:after{
        content: '';
        position: absolute;
        height: 100%;
        width: 4rem;
        left: 100%;
        top: -100%;
        background-color: $primary;
    }
}

.btn{
    color: $white;
    font-weight: 500;
    font-style: normal;
    letter-spacing: .1rem;
    border-radius: inherit;
    box-shadow: 0 -5px 16px 0 rgba($black, .1);
    transition: background-color .4s ease, box-shadow .7s ease;

    &.btn-default{
        padding: .8rem 5rem;
        color: $white;
        font-size: .9rem;
        letter-spacing: inherit;
        background-color: $primary;

        &:hover{
            color: $white;
            background-color: lighten($primary, 5%);
            box-shadow: 0 0px 16px 0 rgba($primary, .3);
        }
    }

    &.btn-slider{
        padding: 1rem 2rem;
        color: $white;
        font-size: .9rem;
        letter-spacing: inherit;
        text-transform: uppercase;
        background-color: rgba($black, .3);

        &:hover{
            color: $white;
            background-color: rgba($black, .6);
        }
		
		@media screen and (max-width: 768px){
                padding: 1rem 1rem;
            }
    }

    &.btn-search{
        border-radius: inherit;
        box-shadow: none;
        background-color: $white;

        &:active{
            background-color: $white;
        }
    }

    &.btn-clear{
        padding: .05rem .8rem;
        color: $gray-500;
        font-size: 1.75rem;
        background-color: $gray-300;
        font-weight: 500;
        font-style: normal;
        letter-spacing: inherith;
        border-radius: 3rem;
        box-shadow: none;
        transition: color .4s ease;

        span{
            margin-right: .5rem;
            font-size: .8rem;
            letter-spacing: .1px;
        }

        &:hover{
            color: $white;
            background-color: $primary;
        }
    }
}

//
//LOADER CSS
//
.loader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    background-color: $white ;
    z-index:1;

    .loader-inner{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);

        & > div{
            background-color: $primary;
        }
    }
}


.mb-6{
    margin-bottom: 4rem !important;
}

.orange {
  color: $primary;
}
.blue {
  color: $secondary;
}

.modal {
	.modal-content{
		padding: 45px 1rem 1rem;
	}
	.modal-header{
		padding: 1rem 0;
		border: none;
	}
	.modal-body{
		padding: 1rem 0;
	}
	.modal-footer{
		padding: 1.5rem 0 0;
	}
	.modal-title{
		font-size: 2.8rem;
		font-weight: 700;
		line-height: initial;
	}
	.btn{
		&.btn-default{
			padding: 0.8rem 3rem;
			border: none;
			border-radius: 0;
		}
	}
	.modal-footer{
		justify-content: center;
		border: none;
	}
}
