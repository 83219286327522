.news-tile{
    max-width: 400px;
    color: $black;
    background-color: $white;
    box-shadow: 0 0 1rem 0 rgba($black, .1);

    @include media-breakpoint-down(md){
        max-width: 100%;
    }

    .data-info{
        font-size: .75rem;
        text-transform: uppercase;

        .date{
            color: $gray-500;
        }
        .tag-label{
            label{
                padding: .3rem 1rem;
                color: $white;
                background-color: $secondary;
            }
        }
    }

    .data-content{
        .title{
            font-weight: 700;
            text-transform: uppercase;
        }
        p{
            color: $gray-700;

            .read-more{
                display: none;
            }
        }
    }
    .data-link{
        a{
            display: block;
            width: 100%;
            color: $white;
            font-size: 1.6rem;
            font-weight: 700;
            border-radius: inherit;
            background-color: $primary;

            &:hover{
                background-color: lighten($primary, 5%);
            }

            &:active{
                background-color: darken($primary, 5%);
            }
        }
    }
}

.product-tile{
    position: relative;
    color: $black;
    background-color: $white;
    overflow: hidden;

    transition: box-shadow .4s ease;

    .data-info{
        overflow: hidden;
        max-height: 270px;

        @include media-breakpoint-down(sm){
            max-height: inherit;
        }

        .tag-label{
            position: absolute;
            top: 1rem;
            left: 0;
            width: auto;
            background-color: $primary;
            z-index: 1;

            label{
                margin-bottom: 0;
                color: $white;
                font-weight: 600;
            }
        }
        .tag-label-lit{
            position: absolute;
            top: 3.5rem;
            left: 0;
            width: auto;
            background-color: $secondary;
            z-index: 1;

            label{
                margin-bottom: 0;
                color: $white;
                font-weight: 600;
            }
        }
        .product-img{
            object-fit: contain;
            object-position: center;
            height: auto;
            transform: translateY(0);

            transition: transform .4s ease;
        }
    }

    .data-link{
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translateY(100%);

        transition: transform .4s ease;

        a{
            display: block;
            width: 100%;
            color: $white;
            font-size: 1.6rem;
            font-weight: 700;
            border-radius: inherit;
            background-color: $primary;

            &:hover{
                background-color: lighten($primary, 5%);
            }

            &:active{
                background-color: darken($primary, 5%);
            }
        }
    }

    &:hover{
        box-shadow: 0 0 1rem 0 rgba($black, .1);

        .data-link{
            transform: translateY(0);
        }
        .data-info{
            .product-img{
                transform: translateY(-5%);
            }
        }
    }
}
