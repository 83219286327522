div.wpcf7-form div.wpcf7-response-output.wpcf7-validation-errors {
    color: #D8000C;
    background-color: #FFBABA;
    border: 0;
    padding: 10px;
}
div.wpcf7-form div.wpcf7-response-output.wpcf7-mail-sent-ok {
    color: #4F8A10;
    background-color: #DFF2BF;
    border: 0;
    padding: 10px;
}
div.wpcf7-mail-sent-ok:before,div.wpcf7-validation-errors:before {
    font: 26px/30px Ionicons;
    margin-right: 16px;
    vertical-align: middle;
}
div.wpcf7-mail-sent-ok:before {
    content: "";
}
div.wpcf7-validation-errors:before {
    content: "";
}

span.wpcf7-not-valid-tip{
    margin-top: .2rem;
    padding: .5rem;
    border-left: 3px solid $red;
    background-color: $white;
    color: $gray-500;
    font-size: .8rem;
}

span.wpcf7-list-item{
    margin: 0 .5em 0 0;
}

div.wpcf7 .ajax-loader{
    display: none;
}
